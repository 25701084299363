import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const SingleImage = makeShortcode("SingleImage");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "depression-what-should-i-look-for"
    }}>{`Depression: What should I look for?`}</h1>
    <TextCustom variant="h5" customColor="blue" paragraph mdxType="TextCustom">
  What is depression?
    </TextCustom>
    <p>{`The word depression is used in many different ways. It is normal for people to feel sad or blue when bad things happen. However, everyday ‘blues’ or sadness is not clinical depression. People with the ‘blues’ may have a temporary depressed mood, but they usually manage to cope and soon recover without treatment. What we are trying to prevent is `}<em parentName="p">{`clinical depression`}</em>{`. `}<em parentName="p">{`Clinical depression`}</em>{` (otherwise referred to as `}<em parentName="p">{`depression`}</em>{`) involves feeling down or irritable for at least two weeks. It stops a person from enjoying things they used to enjoy or from taking part in usual activities, such as going out with friends or playing sport. When this happens, other symptoms also develop.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m8/41-01.svg" alt="child on stairs" mdxType="SingleImage" />
    <p>{`These may include feeling worthless, not sleeping well, not being able to concentrate, having an increased or decreased appetite, or thinking of suicide. The symptoms are severe enough to interfere with everyday life. For example, they make it hard for the person to focus or perform well at school or college, or to get along with friends and family.`}</p>
    <p>{`At home, a teenager with depression may:`}</p>
    <ul>
      <li parentName="ul">{`Complain of tiredness, even if they are sleeping more than usual.`}</li>
      <li parentName="ul">{`Have difficulty doing household chores – either forgetting to do them or not doing them thoroughly.`}</li>
      <li parentName="ul">{`Withdraw from family, spending a lot of time in their bedroom.`}</li>
      <li parentName="ul">{`Be irritable or pick fights with family members.`}</li>
      <li parentName="ul">{`Avoid discussing important future events, such as a decision about further education.`}</li>
    </ul>
    <p>{`You can find out more about what to do to help someone with depression by downloading the `}<GoTo external to="https://mhfa.com.au/sites/mhfa.com.au/files/MHFA_depression_guidelines_A4_2012.pdf" mdxType="GoTo">{`Mental Health First Aid guidelines`}</GoTo>{` for depression.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      